<template>
  <div>
    <b-card>
      <b-row v-if="userData.role==='admin' || userData.role==='manager'">
        <b-col
          cols="12"
          class="d-flex justify-content-end mb-2"
        >
          <span class="card-title" />
          <b-button
            variant="primary"
            @click.prevent="openModal"
          >
            <span class="text-nowrap">Add Task</span>
          </b-button>
        </b-col>
      </b-row>
      <div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows.tasks ? rows.tasks :0"
          :is-loading="loader"
          :search-options="{
            skipDiacritics: true,
            enabled: true,
            externalQuery: searchTerm }"
          mode="remote"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          theme="polar-bear"
          @on-column-filter="columnFilter"
          @on-sort-change="sortFn"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <div
              v-if="props.column.field === 'date'"
            >
              <span>{{ props.row.start_date }} - {{ props.row.end_date }}</span>
            </div>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span class="d-flex justify-content-around">
                <feather-icon
                  id="tooltip-target-4"
                  color="dark"
                  icon="MessageSquareIcon"
                  class="mr-50 cursor-pointer"
                  @click="$router.push({name:'task-chat', params:{name:props.row.work_id},query:{task_name:props.row.title, task:props.row.id}})"
                />
                <b-tooltip
                  target="tooltip-target-4"
                  triggers="hover"
                >
                  Comments
                </b-tooltip>
                <feather-icon
                  id="tooltip-target-1"
                  color="cyan"
                  icon="EyeIcon"
                  class="mr-50 cursor-pointer"
                  @click.prevent="openEditModal(props.row, true)"
                />
                <!--                              @click="$router.push({name:'task-chat', params:{name:props.row.id}})"-->
                <b-tooltip
                  target="tooltip-target-1"
                  triggers="hover"
                >
                  View
                </b-tooltip>
                <feather-icon
                  id="tooltip-target-2"
                  color="dark"
                  icon="Edit2Icon"
                  class="mr-50 cursor-pointer"
                  @click.prevent="openEditModal(props.row, false)"
                />
                <b-tooltip
                  target="tooltip-target-2"
                  triggers="hover"
                >
                  Edit
                </b-tooltip>
                <feather-icon
                  id="tooltip-target-3"
                  color="red"
                  icon="TrashIcon"
                  class="mr-50 cursor-pointer"
                  @click.prevent="deleteTask(props.row.id)"
                />
                <b-tooltip
                  target="tooltip-target-3"
                  triggers="hover"
                >
                  Delete
                </b-tooltip>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  @change="selectedPage(pageLength)"
                />
                <span class="text-nowrap "> of {{ rows.meta && rows.meta.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="rows.meta && rows.meta.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
    <!--  task add  modal-->
    <b-modal
      id="modal-add-task"
      ref="task-add-modal"
      :title="disabled ? 'View Task' : (edit?'Edit Task': 'Add Task')"
      ok-disabled
      cancel-disabled
      @hidden="hideModal('modal-add-task')"
    >
      <validation-observer
        ref="taskObserver"
      >

        <!-- Form -->
        <b-form
          @reset.prevent="clearForm"
        >

          <!-- Title -->

          <b-form-group
            label="Title"
            label-for="task-title"
          >
            <validation-provider
              #default="{ errors }"
              vid="title"
              name="Title"
              rules="required"
            >
              <b-form-input
                id="task-title"
                v-model="task.title"
                :disabled="disabled"
                autofocus
                trim
                placeholder="Task Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Assignee -->
          <b-form-group
            label="Assignee"
            label-for="assignee"
          >
            <validation-provider
              #default="{ errors }"
              vid="assignee_id"
              name="assignee"
              rules="required"
            >
              <v-select
                v-model="task.assignee_id"
                :options="assigneeOptions"
                :reduce="option => option.id"
                label="name"
                class="assignee-selector"
                :disabled="disabled"
                input-id="id"
                placeholder="Select assignee"
                @search="getAssignee"
              >

                <template #option="{ avatar, name }">
                  <b-avatar
                    size="26"
                    :src="avatar"
                  />
                  <span class="ml-50 d-inline-block align-middle"> {{ name }}</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <!-- due Date -->
              <b-form-group
                label="Due Date"
                label-for="due-date"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="end_date"
                  name="duration date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="task.end_date"
                    :disabled="disabled"
                    class="form-control"
                    placeholder="Select end date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!--type -->
              <b-form-group
                label="Task Type"
                label-for="task-type"
              >
                <validation-provider
                  #default="{ errors }"
                  vid="task_type_id"
                  name="task type"
                  rules="required"
                >
                  <v-select
                    v-model="task.task_type_id"
                    :options="taskTypes"
                    :disabled="disabled"
                    label="task_type_name"
                    :reduce="option => option.id"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Select task type"
                    input-id="id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
            v-if="!disabled"
            label="Add Attachment"
          >
            <validation-provider
              #default="{ errors }"
              vid="file"
              name="file"
            >
              <b-form-file
                v-model="attachment"
                :state="errors.length > 0 ? false:null"
                placeholder="Select files for upload"
                drop-placeholder="Drop file here..."
                multiple
                @change="uploadImage($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <br> <small>Upload appropriate files or images</small><br>
            <div
              v-for="(preview, index) in attachment"
              :key="index"
              style=" float: left"
              class="m-md-1 image-area"
            >
              {{ preview.name }}
              <a
                class="remove-image confirm-image"
                style="display: inline;"
                @click="removeImg(preview.id)"
              >&#215;</a>
            </div>
          </b-form-group>
          <!-- Description -->
          <b-form-group
            label="Description"
            label-for="task-description"
          >
            <validation-provider
              #default="{ errors }"
              vid="description"
              name="description"
              rules="required"
            >
              <b-form-textarea
                id="description"
                v-model="task.description"
                :disabled="disabled"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter at least 10 characters"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="edit"
            label="Files"
            label-for="files"
          >
            <b-list-group horizontal>
              <b-row>
                <b-col
                  v-for="(f, index) in filePreviews"
                  :id="f.id"
                  :key="index"
                  md="12"
                >
                  <span>{{ index+1 }}. </span>
                  <b-img
                    v-if="fileType.includes(f.file_type)"
                    thumbnail
                    fluid
                    width="75"
                    height="75"
                    :src="f.file_path"
                  />
                  <a
                    v-else
                    :href="f.file_path"
                    target="_blank"
                  ><b-badge
                    pill
                    variant="info"
                    style="white-space: pre-wrap;"
                  >{{ f.file_name }}</b-badge></a>
                  <span class="float-right cursor-pointer"><feather-icon
                    icon="XIcon"
                    @click="removeUploadedImage(f.id)"
                  /></span>
                </b-col>
              </b-row>
            </b-list-group>
          </b-form-group>
          <b-form-group
            v-if="edit"
            label="Status"
            label-for="status"
          >
            <b-form-select
              v-model="selected"
              @input="updateStatus"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Please select an option
              </b-form-select-option>
              <b-form-select-option value="pending">
                Pending
              </b-form-select-option>
              <b-form-select-option
                value="progress"
              >
                Progress
              </b-form-select-option>
              <b-form-select-option
                value="done"
              >
                Done
              </b-form-select-option>
              <b-form-select-option
                value="expired"
              >
                Expired
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-secondary"
          @click.prevent="hideModal('modal-add-task')"
        >
          {{ disabled ? 'OK' : 'Cancel' }}
        </b-button>
        <b-button
          v-if="disabled"
          size="md"
          variant="outline-primary"
          @click.prevent="disabled=false"
        >
          Edit
        </b-button>
        <b-button
          v-if="!disabled"
          size="md"
          variant="primary"
          @click.prevent="addTask(edit)"
        >
          Submit
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormSelect,
  BRow, BFormSelectOption, BCol, BForm, BFormTextarea, BFormInput, BButton, BFormFile, BTooltip, BListGroup, BImg,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Index',
  components: {
    BTooltip,
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BRow,
    BCol,
    BFormSelectOption,
    BCard,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BListGroup,
    BImg,
    // 3rd party packages
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fileType: ['jpg', 'jpeg', 'png', 'svg', 'webp'],
      selected: null,
      disabled: false,
      edit: false,
      task: {},
      filePreviews: [],
      attachment: [],
      required,
      email,
      url,
      loader: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Title',
          field: 'title',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Search by Title',
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Duration',
          field: 'date',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Executive',
          field: 'executive_name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Type',
          field: 'task_type.task_type_name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Total Comment',
          field: 'number_of_comments',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },

        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
            filterDropdownItems: ['Pending', 'Progress', 'Done', 'Expired'],
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      page: 1,
      data: {},
      userData: getUserData(),
    }
  },

  computed: {
    assigneeOptions() {
      return this.$store.getters['user/users/GET_USERS']
    },
    taskTypes() {
      return this.$store.getters['user/task/getTaskType']
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    rows() {
      return this.$store.getters['user/task/getTasks']
    },
  },
  mounted() {
    this.getTaskList()
    if (this.userData.role === 'admin' || this.userData.role === 'manager') {
      this.getAssignee()
      this.getTaskType()
    }
  },
  methods: {
    updateStatus() {
      this.$store.dispatch('user/task/UPDATE_STATUS', {
        status: this.selected,
        id: this.task.id,
      }).then(() => {
        this.rows.tasks.find(x => x.id === this.task.id).status = this.selected
      })
    },
    // eslint-disable-next-line camelcase
    getTaskList(data = null, current_page = 1) {
      this.loader = true
      document.onkeydown = () => false
      this.$store.dispatch('user/task/GET_TASKS', {
        params: {
          page: current_page,
          per_page: this.pageLength,
          searchTerm: this.searchTerm,
          title: data ? data.title : '',
          sort_by: data ? data.field : '',
          sort_order: data ? data.type : '',
          status: data ? data.status : '',
          work_id: this.$route.query.work_id ?? 1,
        },
      }).then(() => {
        this.loader = false
        document.onkeydown = () => true
      })
    },
    getTaskType() {
      this.$store.dispatch('user/task/GET_TYPE')
    },
    getAssignee(data) {
      this.$store.dispatch('user/users/getAssignees', {
        params: {
          searchTerm: data,
        },
      })
    },
    searchHandler() {
      this.getTaskList(this.page, this.pageLength, this.searchTerm)
    },
    selectedPage(value) {
      this.getTaskList(this.page, value)
    },
    handleChangePage(page) {
      this.getTaskList(page)
    },
    columnFilter(params) {
      this.getTaskList(params.columnFilters)
    },
    sortFn(params) {
      this.getTaskList(params[0])
    },

    openModal() {
      this.disabled = false
      this.edit = false
      this.clearForm()
      this.$bvModal.show('modal-add-task')
    },
    hideModal(modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        this.clearForm()
      })
    },
    clearForm() {
      for (const key in this.task) {
        this.task[key] = null
      }
      this.attachment = []
    },
    addTask(edit) {
      if (edit) {
        this.editTask()
        return
      }
      this.$refs.taskObserver.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('title', this.task.title)
          formData.append('description', this.task.description)
          formData.append('end_date', this.task.end_date)
          formData.append('assignee_id', this.task.assignee_id)
          formData.append('task_type_id', this.task.task_type_id)
          formData.append('file[]', this.task.file)
          formData.append('work_id', this.$route.query.work_id)
          this.$store.dispatch('user/task/STORE_TASK', formData).then(() => {
            this.$refs['task-add-modal'].hide()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                text: 'Work added',
                variant: 'success',
              },
            })
            this.clearForm()
            this.$refs.taskObserver.reset()
            this.getTaskList()
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'XIcon',
                variant: 'warning',
              },
            })
            this.$refs.taskObserver.setErrors(error.response.data.errors)
          })
        }
      })
    },
    openEditModal(item, show = false) {
      this.edit = true
      this.disabled = show
      this.$store.dispatch('user/task/GET_TASK_DETAILS', {
        task_id: item.id,
      }).then(response => {
        this.task.id = response.data.id
        this.task.title = response.data.title
        this.task.assignee_id = response.data.executive_id
        this.task.task_type_id = response.data.task_type.id
        this.task.end_date = new Date(response.data.end_date).toISOString().substr(0, 10)
        this.filePreviews = response.data.file
        this.task.description = response.data.description
        this.selected = response.data.status
        this.$bvModal.show('modal-add-task')
      })
    },
    editTask() {
      this.$refs.taskObserver.validate().then(success => {
        if (success) {
          this.$store.dispatch('user/task/EDIT_TASK', this.task)
            .then(() => {
              this.hideModal('modal-add-task')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  text: 'Task updated',
                  variant: 'success',
                },
              })
              this.edit = false
              this.$refs.taskObserver.reset()
              this.getTaskList()
            })
            .catch(error => {
              this.$refs.taskObserver.setErrors(error.response.data.errors)
            })
        }
      })
    },
    uploadImage(event) {
      this.attachment = event.target.files.length < 1 ? [] : event.target.files
      const formData = new FormData()
      this.attachment.forEach((image, key) => {
        formData.append(`files[${key}]`, image)
      })
      this.$store.dispatch('user/handleFileUpload', formData)
        .then(response => {
          this.task.file = response.data.file_id
          let count = 0
          this.attachment.forEach(item => {
            item.id = this.task.file[count]
            count += 1
          })
        })
    },
    removeImg(item_id) {
      this.attachment.splice(this.attachment.map(item => item.id).indexOf(item_id), 1)
      this.task.file.splice(this.task.file.indexOf(item_id), 1)
    },
    removeUploadedImage(data) {
      this.$store.dispatch('user/file/deleteFile', {
        id: data,
      }).then(() => {
        document.getElementById(data).remove()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File removed successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    deleteTask(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('user/task/DELETE_TASK', item).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your task has been deleted.',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getTaskList()
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.fileinput-remove,
.fileinput-upload {
  display: none;
}
.image-area {
  position: relative;
  width: 25%;
  background: #333;
}

.image-area img {
  max-width: 100%;
  height: auto;
  overflow-wrap: break-word;

}

.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #FFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

.remove-image:hover {
  background: #E54E4E;
}

.remove-image:active {
  background: #E54E4E;
}
</style>
